<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
        <div class="card ptb__4">
            <table class="table">
                <thead>
                <tr>
                    <th>{{ $t('Command.Time') }}</th>
                    <th>{{ $t('Command.Type') }}</th>
                    <th>{{ $t('Command.Text') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <nobr>Apr 14, 2021</nobr>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>GSM</td>
                    <td>Reboot</td>
                </tr>
                <tr>
                    <td>
                        <nobr>Apr 14, 2021</nobr>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>TCP</td>
                    <td>Reboot</td>
                </tr>
                <tr>
                    <td>
                        <nobr>Apr 14, 2021</nobr>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>TCP</td>
                    <td>FLAGS:0;CODE:1;ANSWER:RTMP close command is sent to success</td>
                </tr>
                <tr>
                    <td>
                        <nobr>Apr 14, 2021</nobr>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>GSM</td>
                    <td>Reboot</td>
                </tr>
                <tr>
                    <td>
                        <nobr>Apr 14, 2021</nobr>
                        <nobr>at 12:00 AM</nobr>
                    </td>
                    <td>GSM</td>
                    <td>Reboot</td>
                </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'GroupCommand_Queue',
        props: [
            'groupId',
        ],
        components: {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsByIds",
            ]),
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
        },
        mounted() {
        }
    }
</script>